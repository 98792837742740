import React from 'react';

const TermsAndConditions = () => (
  <section className="bg-white bgdark:bg-gray-900">
    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 bgdark:text-white">
        Terms And Conditions
      </h2>
      <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 bgdark:text-gray-400 sm:text-xl">
        By accessing and using our Logistics Platform, Octaship, you agree to be bound by the
        following Terms and Conditions. These Terms govern your use of the platform for shipping
        services between Nigeria and Canada. Please read these Terms carefully before using our
        services. If you do not agree to any part of these Terms, you may not use our platform.
      </p>
      <div>
        {/* Section 1: Introduction */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          1. Introduction
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          These terms and conditions ("Terms") govern your access to and use of our logistics
          platform ("Octaship"). By using our Platform, you agree to be bound by these Terms. If you
          do not agree with any part of these Terms, please do not use our Platform.
        </p>

        {/* Section 2: Account Registration */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          2. Account Registration
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          To access and utilize the features of the Platform, you must register an account. You must
          provide accurate and complete information during the registration process and keep your
          account credentials confidential. You are solely responsible for all activities that occur
          under your account.
        </p>

        {/* Section 3: Virtual Card Creation */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          3. Shipping Services
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          Octaship provides shipping services for sending packages between Nigeria and Canada. It is
          your responsibility to ensure that the items you ship comply with all relevant laws and
          regulations of both countries. Octaship reserves the right to refuse or terminate shipping
          services at our discretion.
        </p>

        {/* Section 4: User Responsibility */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          4. User Responsibility
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          Users are responsible for accurately declaring the contents and value of shipped items.
          Octaship is not liable for any damage, loss, or delay in delivery, except as otherwise
          stated in these Terms or required by applicable law.
        </p>

        {/* Section 5: Fees and Charges */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          5. Fees and Charges
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          The use of Octaship's Platform and the associated shipping services may be subject to fees
          and charges. You will be notified of any applicable fees before proceeding with the
          transactions. All fees and charges are non-refundable unless otherwise stated.
        </p>

        {/* Section 6: Intellectual Property */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          6. Intellectual Property
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          The Platform and its contents, including but not limited to logos, trademarks, and
          software, are the property of Octaship and are protected by intellectual property laws.
          You are prohibited from using, copying, or distributing any materials from the Platform
          without our prior written consent.
        </p>

        {/* Section 7: Privacy and Data Protection */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          7. Privacy and Data Protection
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          Octaship collects and processes personal data in accordance with our Privacy Policy. By
          using our Platform, you consent to the collection, use, and disclosure of your personal
          information as described in the Privacy Policy.
        </p>

        {/* Section 8: Termination */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          8. Termination
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          Octaship reserves the right to suspend or terminate your access to the Platform at any
          time, with or without cause, and without prior notice. Upon termination, your rights to
          use the Platform and any associated services will cease.
        </p>

        {/* Section 9: Governing Law and Dispute Resolution */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          9. Governing Law and Dispute Resolution
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          These Terms are governed by and construed in accordance with the laws of Jurisdiction. Any
          dispute arising out of or relating to these Terms or your use of the Platform shall be
          resolved through arbitration or mediation, as outlined in the Dispute Resolution section
          of our Platform.
        </p>

        {/* Section 10: Contact Us */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          10. Contact Us
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          If you have any questions or concerns about these Terms, please contact us at
          support@octaship.com
        </p>
      </div>
    </div>
  </section>
);

export default TermsAndConditions;
