import React from 'react';

const PrivacyPolicy = () => (
  <section className="bg-white bgdark:bg-gray-900">
    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 bgdark:text-white">
        Privacy Policy
      </h2>
      <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 bgdark:text-gray-400 sm:text-xl">
        Our Privacy Policy is designed to inform you about how we collect, use, and protect your
        personal information when you use our Logistics Platform, Octaship. We are committed to
        safeguarding your privacy and ensuring the security of your data. By using our platform, you
        consent to the collection and processing of your personal information as outlined in this
        Privacy Policy.
      </p>
      <div>
        {/* Section 1: Introduction */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          1. Introduction
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          This Privacy Policy ("Policy") explains how we collect, use, and protect your personal
          information when you use our logistics platform, Octaship. By using our Platform, you
          consent to the collection and processing of your personal data as described in this
          Policy.
        </p>

        {/* Section 2: Personal Information Collection */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          2. Personal Information Collection
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          In order to use Octaship and access its features, you will be required to provide certain
          personal information, including but not limited to your name, email address, and shipping
          information. Additionally, we may require you to verify your identity by submitting
          appropriate identification documents.
        </p>

        {/* Section 3: Use of Personal Information */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          3. Use of Personal Information
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          We may use the personal information you provide to facilitate shipping services,
          communicate with you, and provide customer support. Your personal information may also be
          used for legal and regulatory compliance purposes related to the provision of logistics
          services.
        </p>

        {/* Section 4: Data Security */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          4. Data Security
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          We take reasonable measures to protect the security of your personal information and
          prevent unauthorized access or disclosure. However, no method of data transmission over
          the internet or electronic storage is completely secure, and we cannot guarantee absolute
          security.
        </p>

        {/* Section 5: Disclosure of Personal Information */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          5. Disclosure of Personal Information
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          We may disclose your personal information to third parties, including shipping partners,
          service providers, and regulatory authorities, as necessary for the provision of our
          logistics services and compliance with applicable laws and regulations.
        </p>

        {/* Section 6: Data Retention */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          6. Data Retention
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          We will retain your personal information for as long as necessary to fulfill the purposes
          for which it was collected and to comply with legal obligations. If you wish to have your
          personal information removed from our systems, please contact us using the information
          provided in Section 9.
        </p>

        {/* Section 7: Third-Party Links */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          7. Third-Party Links
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          Octaship's Platform may contain links to third-party websites or services. We are not
          responsible for the privacy practices or content of those third parties. We encourage you
          to review the privacy policies of any third-party websites or services you visit.
        </p>

        {/* Section 8: Children's Privacy */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          8. Children's Privacy
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          Octaship's Platform is not intended for use by individuals under the age of 18. We do not
          knowingly collect personal information from children. If you are a parent or guardian and
          believe that your child has provided personal information to us, please contact us
          immediately.
        </p>

        {/* Section 9: Contact Us */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          9. Contact Us
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          If you have any questions or concerns about this Privacy Policy, or if you would like to
          exercise your rights regarding your personal information, please contact us at
          support@octaship.com
        </p>
      </div>
    </div>
  </section>
);

export default PrivacyPolicy;
