import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import AccountPage from 'src/pages/dashboard/user/account';
import JobCreatePage from 'src/pages/dashboard/create-shipment';

import { LoadingScreen } from 'src/components/loading-screen';
import OrderListPage from 'src/pages/dashboard/order/list';


const IndexPage = lazy(() => import('src/pages/dashboard/one'));
const PageTwo = lazy(() => import('src/pages/dashboard/two'));
const PageThree = lazy(() => import('src/pages/dashboard/three'));
const PageFour = lazy(() => import('src/pages/dashboard/four'));
const PageFive = lazy(() => import('src/pages/dashboard/five'));
const PageSix = lazy(() => import('src/pages/dashboard/six'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true }
    ],
  },
  {
    path: 'shipment',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'create', element: <JobCreatePage /> },
      { path: 'records', element: <OrderListPage /> },
    ],
  },
  {
    path: 'manage-account',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <AccountPage />, index: true }
    ],
  },
];

// { path: 'two', element: <PageTwo /> },
// { path: 'three', element: <PageThree /> },


// {
//   path: 'shipment',
//   children: [
//     { element: <JobCreatePage />, index: true },
//     { path: 'records', element: <PageFive /> },
//     { path: 'six', element: <OrderDetailsPage /> },
//     { path: 'eight', element: <AccountPage />},

//   ],
// },