import React from 'react';

const ProhibitedItems = () => (
  <section className="bg-white bgdark:bg-gray-900">
    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 bgdark:text-white">
        Prohibited Shipping Items
      </h2>
      <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 bgdark:text-gray-400 sm:text-xl">
        At Octaship, we are committed to ensuring the safety and legality of all shipments. The
        following items are prohibited from being shipped from Canada to Nigeria and vice versa.
        Please review this list carefully to avoid any issues with your shipment.
      </p>
      <div>
        {/* Section 1: Introduction */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          1. Introduction
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          This document outlines the items that are prohibited from being shipped using Octaship's
          services between Canada and Nigeria. It is important to adhere to these restrictions to
          ensure compliance with international shipping regulations and to avoid penalties or
          delays.
        </p>

        {/* Section 2: Prohibited Items */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          2. Prohibited Items
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          The following items are prohibited from being shipped between Canada and Nigeria:
        </p>
        <ul className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl list-disc list-inside">
          <li>Explosives, fireworks, and flammable materials</li>
          <li>Firearms, ammunition, and weapons of any kind</li>
          <li>Hazardous chemicals and toxic substances</li>
          <li>Live animals and plants</li>
          <li>Illegal drugs and narcotics</li>
          <li>Perishable food items</li>
          <li>Counterfeit goods and items that infringe on intellectual property rights</li>
          <li>Human remains or body parts</li>
          <li>Currency, precious metals, and valuable jewelry</li>
          <li>Obscene or pornographic materials</li>
          <li>Battery-powered devices with lithium batteries not properly packaged</li>
          <li>Alcoholic beverages</li>
          <li>Medical waste and biological samples</li>
          <li>Any other items prohibited by local or international law</li>
        </ul>

        {/* Section 3: Compliance and Penalties */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          3. Compliance and Penalties
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          Non-compliance with these restrictions can result in severe penalties, including fines and
          legal action. Octaship reserves the right to refuse shipment of any items that do not
          comply with these regulations and to report violations to the appropriate authorities.
        </p>

        {/* Section 4: Contact Us */}
        <h2 className="mb-4 text-2xl tracking-tight font-bold text-left text-gray-900 bgdark:text-white">
          4. Contact Us
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-1xl text-left text-gray-500 bgdark:text-gray-400 sm:text-xl">
          If you have any questions or concerns regarding prohibited items, please contact us at
          support@octaship.com. We are here to assist you in ensuring that your shipments are
          compliant with all regulations and requirements.
        </p>
      </div>
    </div>
  </section>
);

export default ProhibitedItems;
