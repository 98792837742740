// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  SHIPMENT: '/shipment',
  ACCOUNT: '/manage-account',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: '',
  // AUTH
  auth: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
      resetPassword: `${ROOTS.AUTH}/reset-password`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,

  },
  shipment: {
    create: `${ROOTS.SHIPMENT}/create`,
    records: `${ROOTS.SHIPMENT}/records`,
    track: `${ROOTS.SHIPMENT}/track/`,
  },
  account: {
    root: ROOTS.ACCOUNT,
  },
};

   // shipment: {
    //   root: `${ROOTS.DASHBOARD}/shipment`,
    //   records: `${ROOTS.DASHBOARD}/shipment/records`,
    //   six: `${ROOTS.DASHBOARD}/shipment/eight`,
    // }, 