import { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import Home from 'src/pages/home/home';
import AboutUs from 'src/pages/home/about-us';

import { AuthGuard } from 'src/auth/guard';
import PrivacyPolicy from 'src/pages/home/privacy';
import DashboardLayout from 'src/layouts/dashboard';
import TermsAndConditions from 'src/pages/home/terms';
import OrderDetailsPage from 'src/pages/dashboard/order/details';
import { Helmet } from 'react-helmet-async';

import { LoadingScreen } from 'src/components/loading-screen';

import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import ProhibitedItems from 'src/pages/home/prohibitedItems';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <>
          <Helmet>
            <title> Home</title>
          </Helmet>
          <Home />
        </>
      ),
    },
    {
      path: '/terms',
      element: (
        <>
          <Helmet>
            <title>Terms and Conditions</title>
          </Helmet>
          <TermsAndConditions />
        </>
      ),
    },
    {
      path: '/privacy-policy',
      element: (
        <>
          <Helmet>
            <title>Privacy Policy</title>
          </Helmet>
          <PrivacyPolicy />
        </>
      ),
    },
    {
      path: '/prohibited-items',
      element: (
        <>
          <Helmet>
            <title>Prohibited Items</title>
          </Helmet>
          <ProhibitedItems />
        </>
      ),
    },

    {
      path: '/about-us',
      element: (
        <>
          <Helmet>
            <title>About us</title>
          </Helmet>
          <AboutUs />
        </>
      ),
    },
    {
      path: '/shipment/track/:id',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <OrderDetailsPage />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
