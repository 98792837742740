import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';


// ----------------------------------------------------------------------

export default function OrderDetailsHistory({ history }) {
  // const renderSummary = (
  //   <Stack
  //     spacing={2}
  //     component={Paper}
  //     variant="outlined"
  //     sx={{
  //       p: 2.5,
  //       minWidth: 260,
  //       flexShrink: 0,
  //       borderRadius: 2,
  //       typography: 'body2',
  //       borderStyle: 'dashed',
  //     }}
  //   >
  //     <Stack spacing={0.5}>
  //       <Box sx={{ color: 'text.disabled' }}>Order time</Box>
  //       {fDateTime(history.orderTime)}
  //     </Stack>
  //     <Stack spacing={0.5}>
  //       <Box sx={{ color: 'text.disabled' }}>Payment time</Box>
  //       {fDateTime(history.orderTime)}
  //     </Stack>
  //     <Stack spacing={0.5}>
  //       <Box sx={{ color: 'text.disabled' }}>Delivery time for the carrier</Box>
  //       {fDateTime(history.orderTime)}
  //     </Stack>
  //     <Stack spacing={0.5}>
  //       <Box sx={{ color: 'text.disabled' }}>Completion time</Box>
  //       {fDateTime(history.orderTime)}
  //     </Stack>
  //   </Stack>
  // );

  const renderTimeline = (
    <Timeline
      sx={{
        p: 0,
        m: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {history && history.length >= 1 && history.reverse().map((item, index) => {
        const firstTimeline = index === 0;

        const lastTimeline = index === history.length - 1;

        return (
          <TimelineItem key={item.title}>
            <TimelineSeparator>
              <TimelineDot color={(firstTimeline && 'primary') || 'grey'} />
              {lastTimeline ? null : <TimelineConnector />}
            </TimelineSeparator>

            <TimelineContent>
              <Typography variant="subtitle2">{item.note}</Typography>

              <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
                {item.date}
              </Box>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );

  return (
    <Card>
      <CardHeader title="Tracking" />
      <Stack
        spacing={3}
        alignItems={{ md: 'flex-start' }}
        direction={{ xs: 'column-reverse', md: 'row' }}
        sx={{ p: 3 }}
      >
        {renderTimeline}
      </Stack>
    </Card>
  );
}

OrderDetailsHistory.propTypes = {
  history: PropTypes.object,
};

//