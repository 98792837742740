import PropTypes from 'prop-types';
import { useMemo, useEffect, useReducer, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { setSession } from './utils';
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const navigate = useNavigate()

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem("token");
      const user = localStorage.getItem("data")
      
      if (accessToken) {

        dispatch({
          type: 'INITIAL',
          payload: {
            user: JSON.parse(user),
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {

    
    const req = await fetch("https://api.octaship.com/api/login", {
      method: "POST",
      body: JSON.stringify({
        email,
        password
      }),
    });
    
    const reqData = await req.json();


    if(reqData && reqData.status_code === 200 && reqData.profile){

    const {token} = reqData;
    localStorage.setItem("data", JSON.stringify({
      ...reqData.profile,
      token
  }));

   sessionStorage.setItem("token", JSON.stringify(token))

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...reqData.profile,
          token
        },
      },
    });

    return {
      error: false,
      message: ''
    }
  }

  return {
    error: true,
    message: reqData.status_desc
  }

  }, []);


  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName, homeAdd, city, country, phoneNumber, state) => {

    
    const req = await fetch("https://api.octaship.com/api/signup", {
      method: "POST",
      body: JSON.stringify({
        email,
        fname: firstName,
        lname: lastName,
        home_addr: homeAdd,
        city,
        country,
        phone: phoneNumber,
        password,
        state
      }),
    });
    
    const reqData = await req.json();

    if(reqData && reqData.status_code === 200) {
    return {
    error: false,
    message: reqData.status_desc
    }
    }
 
  return {
    error: true,
    message: reqData.status_desc
  }


  }, []);

  // LOGOUT
  const logout = async () => {
  await sessionStorage.removeItem('token')
  await localStorage.removeItem('data')
    dispatch({
      type: 'LOGOUT',
    });
    
    navigate("/")
  }

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
