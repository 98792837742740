import React from "react";

export default function AboutUs() {
  return (
    <div>
      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
              <img
                alt="Shipping"
                src="https://images.pexels.com/photos/6169002/pexels-photo-6169002.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>

            <div className="lg:py-24">
              <h2 className="text-3xl font-bold sm:text-4xl">About Octaship</h2>

              <p className="mt-4 text-gray-600">
                Welcome to Octaship, your trusted logistics partner for shipping
                packages between Canada and Nigeria. Our commitment is to
                provide reliable and efficient shipping services, connecting
                businesses and individuals across borders.
              </p>
              <p className="mt-4 text-gray-600">
                With Octaship, your packages are in safe hands. We strive to
                make international shipping seamless and hassle-free, ensuring
                your shipments reach their destination on time.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 md:py-16 lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
              Our Future Goals
            </h2>

            <p className="mt-4 text-gray-500 sm:text-xl">
              Octaship is dedicated to achieving the following goals and
              constantly improving our logistics platform to offer the best
              experience to our clients.
            </p>
          </div>

          <div className="mt-8 sm:mt-12">
            <dl className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <div className="flex flex-col rounded-lg border border-gray-100 px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Packages Shipped Annually
                </dt>
                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">
                  100K+
                </dd>
              </div>

              <div className="flex flex-col rounded-lg border border-gray-100 px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  On-time Delivery Rate
                </dt>
                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">
                  98%
                </dd>
              </div>

              <div className="flex flex-col rounded-lg border border-gray-100 px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Total Routes Covered
                </dt>
                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">
                  20+
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
    </div>
  );
}