import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuthContext } from 'src/auth/hooks';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



// ----------------------------------------------------------------------

export default function OrderDetailsInfo({ data }) {
  const { user } = useAuthContext()

  const [open, setOpen] = React.useState(false);
  const [siteData, setSiteData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  async function handleGetDetails(){
    setLoading(true)
    const req = await fetch("https://api.octaship.com/api/fetch_site_info", {
      method: "GET",
      headers: {
        "Authorization": user.token
      },
    })
    
    const reqData = await req.json();

    if(reqData.status_code === 200){
    setSiteData(reqData.info)
    setLoading(false)
    handleOpen()
    }else {
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        setLoading(false)
    }
  }

  return (
    <Card>
        {siteData && <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Payment Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {data.payment_method === "bank_transfer_ng" ? `Please send ₦${data.shipping_fee} to this account details below for your shipment to be processed` : `Please send $CAD${data.shipping_fee} to this payment details below for your shipment to be processed`}  
          </Typography>
          {data.payment_method === "bank_transfer_ng" ? <>
          <div style={{ marginTop: 10}} />
          <p><strong>Account Name: </strong>{siteData ? siteData.ng_acct_name : ''}</p>
          <div style={{ marginTop: 10}} />
          <p><strong>Account Number: </strong>{siteData ? siteData.ng_acct_num : ''}</p>
          <div style={{ marginTop: 10}} />
          <p><strong>Bank Name: </strong>{siteData ? siteData.ng_bank_name : ''}</p>
          <div style={{ marginTop: 10}} />
          <p><strong>Payment Reference(put in narration): </strong>{data.shipping_id}</p>
          </> : <>
          <div style={{ marginTop: 10}} />
          <p><strong>Email: </strong>{siteData.canada_transfer_email}</p>
          <div style={{ marginTop: 10}} />
          <p><strong>Payment Reference(put in narration): </strong>{data.shipping_id}</p>
          </>}
        </Box>
      </Modal>}
      {data.shipping_method === "pickup" &&
      <>
           <CardHeader
        title="Pickup Info"
      />
      <Stack direction="row" sx={{ p: 3 }}>
        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle2">{}</Typography>
          <Box sx={{ color: 'text.secondary' }}>{data.pickup_address.pickup_country}</Box>
          <Box>
          {data.pickup_address.pickup_state}
            <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
            {data.pickup_address.pickup_city}, {data.pickup_address.pickup_home_address}
            </Box>
          </Box>
        </Stack>
      </Stack>
      </> 
        }
           {data.shipping_method === "dropoff" &&
      <>
           <CardHeader
        title="Dropoff Location"
      />
      <Stack direction="row" sx={{ p: 3 }}>
        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle2">{}</Typography>
          <Box sx={{ color: 'text.secondary' }}>{data.dropoff_location}</Box>    
        </Stack>
      </Stack>
      </> 
        }
      <Divider sx={{ borderStyle: 'dashed' }} />
      <CardHeader
        title="Reciever Info"
      />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Name
          </Box>
         {data.receiver_name}
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Email
          </Box>
          <Link underline="always" color="inherit">
           {data.receiver_email}
          </Link>
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <CardHeader
        title="Shipping to"
      />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Address
          </Box>
          {data.destination_address.dest_home_address}, {data.destination_address.dest_state}, {data.destination_address.dest_country}
        </Stack>

        <Stack direction="row">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Phone number
          </Box>
          {data.receiver_phone}
        </Stack>
      </Stack>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <CardHeader
        title="Payment Info"
      />
      <Stack direction="row" alignItems="center" sx={{ p: 3, typography: 'body2' }}>
        Method
        <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
        </Box>
        {data.payment_method === "bank_transfer_ng" ? "Bank Transfer" : "Email (Interact)"}      
      </Stack>
     {data.status === "pending" &&
      <Stack direction="row" alignItems="center" sx={{ p: 3, typography: 'body2' }}>
        Still havent made the payment yet?
        <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
        </Box>
        <Button variant="contained" type="button" onClick={() => handleGetDetails()} color="primary">
            {loading ? "Loading.." : "Show Details"}
              </Button>   
      </Stack>}
    </Card>
  );
  
}