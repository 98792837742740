import Container from '@mui/material/Container';

import { useSettingsContext } from 'src/components/settings';

import JobNewEditForm from '../job-new-edit-form';

// ----------------------------------------------------------------------

export default function JobCreateView() {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
   

      <JobNewEditForm />
    </Container>
  );
}
