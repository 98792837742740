import * as Yup from 'yup';
import sum from 'lodash/sum';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Select from 'react-select';
import { Modal } from 'antd';
import { LoadingScreen } from 'src/components/loading-screen';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import ButtonBase from '@mui/material/ButtonBase';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRouter } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';

import { Modal as ReactModal } from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

import CanadaIcon from '../../assets/canada.png';
import NigeriaIcon from '../../assets/nigeria.png';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { countries } from 'src/assets/data';
import { useAuthContext } from 'src/auth/hooks';
import Animation from 'src/assets/pending.json';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField, RHFAutocomplete } from 'src/components/hook-form';

import AddShipmentItems from './items-lists';
import { useNavigate } from 'react-router';
//
import InteractPNG from 'src/assets/interact.png';
import BankIcon from 'src/assets/bank.png';

import { State, City } from 'country-state-city';
// ----------------------------------------------------------------------

const pickUpLocations = [
  { city: 'Toronto', fee: 25 },
  { city: 'Mississauga', fee: 35 },
  { city: 'Hamilton', fee: 40 },
  { city: 'Brampton', fee: 35 },
  { city: 'Kitchener', fee: 40 },
  { city: 'London', fee: 100 },
  { city: 'Markham', fee: 30 },
  { city: 'Niagara Falls', fee: 100 },
  { city: 'Windsor', fee: 100 },
  { city: 'Richmond Hill', fee: 25 },
  { city: 'Oakville', fee: 40 },
  { city: 'Burlington', fee: 40 },
  { city: 'Nepean', fee: 60 },
  { city: 'Barrie', fee: 45 },
  { city: 'Oshawa', fee: 40 },
  { city: 'Guelph', fee: 30 },
];

export default function JobNewEditForm({ currentJob }) {
  const router = useRouter();
  const { user } = useAuthContext();
  const [page, setPage] = useState(1);
  const [siteData, setSiteData] = useState(null);
  const [quoteGenerated, setQuoteGenerated] = useState(false);
  const [loadingFee, setLoadingFee] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [senderPhoneNumber, setSenderPhoneNumber] = useState('');
  const [senderState, setSenderState] = useState('');
  const [senderCity, setSenderCity] = useState('');
  const [shipmentMethod, setShipmentMethod] = useState('');
  const [recieverCountry, setRecieverCountry] = useState('');
  const [recieverPhoneNumber, setRecieverPhoneNumber] = useState('');
  const [recieverState, setRecieverState] = useState('');
  const [selectedPickupCity, setSelectedPickupCity] = useState(null);
  const [recieverCity, setRecieverCity] = useState('');
  const [dropoffLoc, setDropOffLoc] = useState('');
  const [shipmentId, setShipmentId] = useState('');
  const [isSelectCountDisabled, setIsSelectedCountDisabled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [paymentCurrency, setPaymentCurrency] = useState('');
  const [showInitialModal, setShowInitialModal] = useState(true);
  const [invalidSenderPhone, setInvalidSenderPhone] = useState(false);
  const [invalidRecieverPhone, setInvalidRecieverPhone] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [modalPage, setModalPage] = useState(1);
  const [paymentModalPage, setPaymentModalPage] = useState(1);
  const [isModalCLosable, setIsModalClosable] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const mdUp = useResponsive('up', 'md');
  const [steps, setStep] = useState({
    stepsItems: [
      'Sender Details',
      'Reciever Details',
      'Items Descriptions',
      'Order Summary',
      'Success',
    ],
    currentStep: 1,
  });

  const navigate = useNavigate();

  const NewJobSchema = Yup.object().shape({
    senderName: Yup.string().required('Sender name is required'),
    senderPhoneNumber: Yup.string().required('Sender phone number is required'),
    senderState: Yup.string().required('Sender state is required'),
    recieverName: Yup.string().required('Reciever name is required'),
    recieverEmail: Yup.string().required('Reciever email is required'),
    recieverPhoneNumber: Yup.string().required('Reciever phone number is required'),
    recieverAddress: Yup.string().required('Reciever address is required'),
    recieverCity: Yup.string().required('Reciever city is required'),
    recieverState: Yup.string().required('Reciever state is required'),
    recieverPostalCode: Yup.string().required('Reciever postal code is required'),
    shippingCountry: Yup.string().required('Shipping country is required'),
    destinationCountry: Yup.string().required('Destination country is required'),

    // SISIIS
    // employmentTypes: Yup.array().min(1, 'Choose at least one option'),
    // role: Yup.string().required('Role is required'),
    // skills: Yup.array().min(1, 'Choose at least one option'),
    // workingSchedule: Yup.array().min(1, 'Choose at least one option'),
    // benefits: Yup.array().min(1, 'Choose at least one option'),
    // locations: Yup.string().min(1, 'Choose at least one option'),
    // expiredDate: Yup.mixed().nullable().required('Expired date is required'),
    // salary: Yup.object().shape({
    //   type: Yup.string(),
    //   price: Yup.number().min(1, 'Price is required'),
    //   negotiable: Yup.boolean(),
    // }),
    // experience: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      senderName: '',
      senderPhoneNumber: '',
      senderAddress: '',
      senderAddress2: '',
      senderState: '',
      recieverName: '',
      recieverEmail: '',
      recieverAddress: '',
      recieverAddress2: '',
      recieverState: '',
      recieverPostalCode: '',
      senderPostalCode: '',
      shippingCountry: '',
      comment: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewJobSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
    resetField,
    setValue,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const handleRemoveItem = (index) => {
    remove(index);
  };

  const {
    senderName,
    senderEmail,
    senderAddress,
    senderPostalCode,
    recieverName,
    receiverEmail,
    recieverAddress,
    receiverAddress2,
    recieverNearestLandmark,
    recieverPostalCode,
    items,
    paymentAccountName,
    paymentAccountNumber,
    paymentBankName,
    paymentEmail,
  } = control ? control._formValues : defaultValues;

  const summaryData = {
    senderName,
    senderEmail,
    senderAddress,
    senderPostalCode,
    recieverName,
    receiverEmail,
    recieverAddress,
    receiverAddress2,
    recieverNearestLandmark,
    recieverPostalCode,
    items,
  };

  const feeToUse = siteData
    ? control._formValues.shippingCountry === 'Nigeria'
      ? siteData.ng_fee_per_kg
      : siteData.cd_fee_per_kg
    : '';

  const totalOnRow = feeToUse
    ? control._formValues.items
      ? control._formValues.items.map((item) => feeToUse * item.weight * item.quantity)
      : []
    : [];
  const subTotal = sum(totalOnRow);

  const totalAmount = subTotal;

  function filterCities() {
    const code = control && control._formValues.destinationCountry === 'Nigeria' ? 'NG' : 'CA';
    const statesData = State.getStatesOfCountry(code);

    const stateCode = statesData.find((a) => a.name === control._formValues.recieverState).isoCode;

    const citiesData = City.getCitiesOfState(code, stateCode);

    return citiesData;
  }

  function pickupCities() {
    const code = control && control._formValues.shippingCountry === 'Nigeria' ? 'NG' : 'CA';
    const statesData = State.getStatesOfCountry(code);

    const stateCode = statesData.find((a) => a.name === control._formValues.pickupState).isoCode;

    const citiesData = City.getCitiesOfState(code, stateCode);

    return citiesData;
  }

  function returnPickUpFeeCost() {
    const dollarRate = 1360;

    if (selectedPickupCity) {
      const findCity = pickUpLocations.find((a) => a.city === selectedPickupCity?.value);
      if (!findCity) return 0.0;

      if (paymentCurrency !== 'CA') {
        return dollarRate * findCity.fee;
      } else {
        return findCity.fee;
      }
    } else {
      return 0.0;
    }
  }

  const getRecieverCities =
    control && control._formValues.destinationCountry && control._formValues.recieverState
      ? filterCities()
      : [];

  const getPickupCities =
    control && control._formValues.shippingCountry && control._formValues.pickupState
      ? pickupCities()
      : [];

  function itemsToRender() {
    const array1 = [
      {
        label: 'Pickup',
        icon: <Iconify icon="solar:clock-circle-bold" width={32} sx={{ mb: 2 }} />,
      },
      {
        label: 'Dropoff',
        icon: <Iconify icon="solar:wad-of-money-bold" width={32} sx={{ mb: 2 }} />,
      },
    ];
    const array2 = [
      {
        label: 'Dropoff',
        icon: <Iconify icon="solar:wad-of-money-bold" width={32} sx={{ mb: 2 }} />,
      },
    ];

    if (control._formValues.shippingCountry === 'Nigeria') {
      return array2;
    } else if (control._formValues.shippingCountry === 'Canada') {
      return array1;
    } else {
      return [];
    }
  }

  const handleChangePage = () => {
    setPage(2);
  };

  function notifyUser(text, error) {
    if (error) {
      toast.error(text, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    toast.success(text, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  //  async function handleCreateShipment(){
  //   const formData = new FormData();

  //   formData.append("company", control._formValue.recieverName);
  //   formData.append("country", control._formValue.destinationCountry);
  //   formData.append("phone", control.senderPhoneNumber);
  //   formData.append("r_phone", recieverPhoneNumber);
  //   formData.append("state", recieverState);
  //   formData.append("email", user.email);
  //   formData.append("address", recieverAddress);
  //   formData.append("city", recieverCity);
  //   formData.append("comment", comment);
  //   formData.append("r_email", recieverEmail);
  //   formData.append("method", deliveryOption);
  //   formData.append("package_info", packages);

  //   if(deliveryOption === "pickup") {
  //     formData.append("pickup_country", user.country);
  //     formData.append("pickup_state", pickupState);
  //     formData.append("pickup_city", pickupCity);
  //     formData.append("pickup_home_addr", pickupAddress);
  //   }else {
  //     formData.append("dropoff_loc", dropLocation);
  //   }

  //   const req = await fetch("https://api.octaship.com/api/create_shipment", {
  //     method: "POST",
  //     headers: {
  //       Authorization: token,
  //     },
  //     body: formData,
  //   });

  //   const reqData = await req.json();

  //   console.log(reqData)

  //   if(reqData.status_code === 200) {
  //   }
  //  }

  const accessToken = user.token;

  function openRelativeLinkInNewTab(relativeUrl) {
    const absoluteUrl = new URL(relativeUrl, window.location.origin);
    window.open(absoluteUrl.href, '_blank').focus();
  }


  function validateNigeriaPhoneNumber(phoneNumber) {
    // Regular expression for Nigeria phone numbers
    const nigeriaRegex = /^(\+234)[6789]\d{9}$/;

    // Check if the phone number matches Nigeria format
    return nigeriaRegex.test(phoneNumber);
  }

  function validateCanadaPhoneNumber(phoneNumber) {
    // Regular expression for Canada phone numbers
    const canadaRegex = /^(\+1)[2-9]\d{9}$/;

    // Check if the phone number matches Canada format
    return canadaRegex.test(phoneNumber);
  }

  function getSenderCountryStates(type) {
    if (type === 'sender') {
      if (!selectedCountry) return [];
      let dataToSend = [];
      const data = State.getStatesOfCountry(selectedCountry.value === 'nigeria' ? 'NG' : 'CA');

      data.forEach((element) => {
        dataToSend.push({ value: element.isoCode, label: element.name });
      });

      return dataToSend;
    } else {
      if (!recieverCountry) return [];
      let dataToSend = [];
      const data = State.getStatesOfCountry(recieverCountry.value === 'nigeria' ? 'NG' : 'CA');

      data.forEach((element) => {
        dataToSend.push({ value: element.isoCode, label: element.name });
      });

      return dataToSend;
    }
  }

  function getSenderCountryCities(type) {
    if (type === 'sender') {
      if (!selectedCountry || !senderState) return [];
      let dataToSend = [];
      const data = City.getCitiesOfState(
        selectedCountry.value === 'nigeria' ? 'NG' : 'CA',
        senderState.value
      );

      data.forEach((element) => {
        dataToSend.push({ value: element.isoCode, label: element.name });
      });

      return dataToSend;
    } else {
      if (!recieverCountry || !recieverState) return [];
      let dataToSend = [];
      const data = City.getCitiesOfState(
        recieverCountry.value === 'nigeria' ? 'NG' : 'CA',
        recieverState.value
      );

      data.forEach((element) => {
        dataToSend.push({ value: element.isoCode, label: element.name });
      });

      return dataToSend;
    }
  }

  async function handleCancelShipment() {
    const reConfirm = window.confirm('Shall we proceed the cancellation of your shipment?');

    if (reConfirm) {
      setCancelLoading(true);
      const req = await fetch('https://api.octaship.com/api/delete_shipment', {
        method: 'POST',
        headers: {
          Authorization: accessToken,
        },
        body: JSON.stringify({
          shipping_id: shipmentId,
        }),
      });

      const reqData = await req.json();

      if (reqData && reqData.status_code === 200) {
        toast.success('Request has been processed', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setCancelLoading(false);
        navigate('/dashboard');
      } else {
        setCancelLoading(false);
        toast.error(reqData.status_desc, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } else {
      return;
    }
  }

  function calculateShippingAndTax() {
    if (!siteData) return {};
    const isCanada = selectedCountry.value === 'canada';
    const feePerKg =
      paymentCurrency === 'CA' ? Number(siteData.cd_fee_per_kg) : Number(siteData.ng_fee_per_kg);
    const taxFeePercentage = isCanada ? Number(siteData.cd_tax_fee) : 0;

    let totalWeight = 0;
    let shippingFee = 0;

    control._formValues.items.forEach((element) => {
      const { weight, quantity } = element;
      totalWeight += weight * quantity;
    });

    shippingFee = totalWeight * feePerKg;
    const taxFee = (shippingFee * taxFeePercentage) / 100;
    const total =
      paymentCurrency !== 'CA'
        ? shippingFee + taxFee
        : shippingFee + taxFee + returnPickUpFeeCost();

    return {
      shippingFee,
      taxFee,
      total,
    };
  }

  function validateForm() {
    const {
      senderName,
      senderPhoneNumber,
      recieverName,
      recieverEmail,
      recieverPhoneNumber,
      recieverAddress,
      recieverCity,
      recieverState,
      recieverPostalCode,
      shippingCountry,
      shippingMethod,
      comment,
      pickupAddress,
      pickupCity,
      pickupState,
      destinationCountry,
      items,
    } = control ? control._formValues : defaultValues;

    // Check if any of the required fields is empty
    if (
      !senderName ||
      !senderPhoneNumber ||
      !recieverName ||
      !recieverEmail ||
      !recieverPhoneNumber ||
      !recieverAddress ||
      !recieverCity ||
      !recieverState ||
      !recieverPostalCode ||
      !shippingCountry ||
      !shippingMethod ||
      !destinationCountry
    ) {
      return true;
    }

    // Check if pickup details are provided when shippingMethod is "Pickup"
    if (shippingMethod === 'Pickup' && (!pickupAddress || !pickupCity || !pickupState)) {
      return true;
    }

    if (destinationCountry === 'Nigeria') {
      const isValid = validateNigeriaPhoneNumber(recieverPhoneNumber);
      if (!isValid) {
        return true;
      }
    } else {
      const isValid = validateCanadaPhoneNumber(recieverPhoneNumber);
      if (!isValid) {
        return true;
      }
    }

    if (shippingCountry === 'Nigeria') {
      const isValid = validateNigeriaPhoneNumber(senderPhoneNumber);
      if (!isValid) {
        return true;
      }
    } else {
      const isValid = validateCanadaPhoneNumber(senderPhoneNumber);
      if (!isValid) {
        return true;
      }
    }

    // All checks passed, return true
    return false;
  }

  function handleProceedToPageTwo(e) {
    e.preventDefault();

    const invalidPackages = [];

    if (control._formValues.items.length >= 1) {
      control._formValues.items.forEach((element) => {
        if (!element.title || !element.type || !element.quantity || !element.weight) {
          invalidPackages.push(element);
        }
      });

      if (invalidPackages.length >= 1) {
        toast.error('All packages fields are required.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }
    } else {
      toast.error('Please add a package.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    if (control._formValues.shippingCountry === control._formValues.destinationCountry) {
      toast.error("You can't ship to the same country", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    setPage(2);
  }

  function validateEmail(email) {
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  }

  function sendNotification(text, type) {
    toast(text, { type: type });
  }

  function proceedToNextStep() {
    const {
      senderName,
      senderEmail,
      senderAddress,
      senderPostalCode,
      recieverName,
      receiverEmail,
      recieverAddress,
      receiverAddress2,
      recieverNearestLandmark,
      recieverPostalCode,
      items,
    } = control._formValues;

    if (currentIndex === 1) {
      if (!senderName) {
        sendNotification('Full name is required', 'error');
        return;
      } else if (!senderEmail) {
        sendNotification('Email is required', 'error');
        return;
      } else if (!senderAddress) {
        sendNotification('Address is required!', 'error');
        return;
      } else if (!senderPostalCode) {
        sendNotification('Postal code is required!', 'error');
        return;
      } else if (!selectedCountry) {
        sendNotification('Country is required!', 'error');
        return;
      } else if (!senderState) {
        sendNotification('State is required!', 'error');
        return;
      } else if (!senderCity) {
        sendNotification('City is required!', 'error');
        return;
      } else if (!senderPhoneNumber) {
        sendNotification('Phone number is required!', 'error');
        return;
      }

      if (senderName.length < 5) {
        sendNotification('Invalid Fullname', 'error');
        return;
      }

      if (!validateEmail(senderEmail)) {
        sendNotification('Invalid email', 'error');
        return;
      }

      if (senderAddress?.length < 5) {
        sendNotification('Invalid address', 'error');
        return;
      }

      setStep({
        ...steps,
        currentStep: steps.currentStep + 1,
      });
      setCurrentIndex(2);
      window.scrollTo(0, 0);
      return;
    }

    if (currentIndex === 2) {
      if (!recieverName) {
        sendNotification('Full name is required', 'error');
        return;
      } else if (!receiverEmail) {
        sendNotification('Email is required', 'error');
        return;
      } else if (!recieverPostalCode) {
        sendNotification('Postal code is required!', 'error');
        return;
      } else if (!recieverCountry) {
        sendNotification('Country is required!', 'error');
        return;
      } else if (!recieverState) {
        sendNotification('State is required!', 'error');
        return;
      } else if (!recieverCity) {
        sendNotification('City is required!', 'error');
        return;
      } else if (!recieverPhoneNumber) {
        sendNotification('Phone number is required!', 'error');
        return;
      }

      if (recieverName.length < 5) {
        sendNotification('Invalid Fullname', 'error');
        return;
      }

      if (!validateEmail(receiverEmail)) {
        sendNotification('Invalid email', 'error');
        return;
      }

      if (recieverAddress?.length < 5) {
        sendNotification('Invalid address', 'error');
        return;
      }

      if (selectedCountry.value === recieverCountry.value) {
        sendNotification('You cant ship to the same country');
        return;
      }

      setStep({
        ...steps,
        currentStep: steps.currentStep + 1,
      });
      setCurrentIndex(3);
      window.scrollTo(0, 0);
      return;
    }

    if (currentIndex === 3) {
      if (!items || items.length === 0) {
        sendNotification('Please add a package', 'error');
        return;
      }

      let hasError = false;
      let errorMsg = '';

      items.forEach((element) => {
        if (!element.title || !element.quantity || !element.weight) {
          hasError = true;
          errorMsg = 'One of packages is missing some values';
          return;
        }

        if (Number(element.quantity) === 0 || Number(element.weight) === 0) {
          hasError = true;
          errorMsg = 'Values cannot contain negative values';
          return;
        }
      });

      if (hasError) {
        sendNotification(errorMsg, 'error');
        return;
      }

      if (!siteData) {
        handleGenerateQuote();
        return;
      }

      setStep({
        ...steps,
        currentStep: steps.currentStep + 1,
      });
      setCurrentIndex(4);
      window.scrollTo(0, 0);
      return;
    }
  }

  async function createShipment() {
    setSubmitLoading(true);
    // setTimeout(() => {
    //   setShowPaymentModal(false);
    //   setStep({
    //     ...steps,
    //     currentStep: steps.currentStep + 2,
    //   });
    //   setPage(2);
    //   setSubmitLoading(false);
    // }, 10000);


    const {
      senderName,
      senderPhoneNumber,
      senderState,
      recieverName,
      recieverEmail,
      recieverPhoneNumber,
      recieverAddress,
      recieverCity,
      recieverState,
      recieverPostalCode,
      shippingCountry,
      shippingMethod,
      comment,
      pickupAddress,
      pickupCity,
      pickupState,
      destinationCountry,
      items,
      paymentMethod,
      emailToSendWith,
      pickupPostalCode,
    } = control ? control._formValues : defaultValues;

    console.log(emailToSendWith);

    const reqHeaderData = {
      sender: {
        country: shippingCountry,
        pickup_country: shippingMethod === 'Pickup' ? shippingCountry : '',
        pickup_state: pickupState,
        pickup_city: pickupCity,
        pickup_home_addr: pickupAddress,
        pickup_postal_code: pickupPostalCode,
        phone: senderPhoneNumber,
        dropoff_loc:
          shippingMethod === 'Dropoff'
            ? shippingCountry === 'Nigeria'
              ? siteData.ng_dropoff_location
              : siteData.cd_dropoff_location
            : '',
      },
      receiver: {
        email: recieverEmail,
        country: destinationCountry,
        state: recieverState,
        city: recieverCity,
        address: recieverAddress,
        postal_code: recieverPostalCode,
        name: recieverName,
        phone: recieverPhoneNumber,
      },
      others: {
        comment: comment,
        method: shippingMethod.toLowerCase(),
        payment_method:
          paymentMethod === 'Bank Transfer' ? 'bank_transfer_ng' : 'email_transfer_cd',
        email_to_send_with: emailToSendWith ? emailToSendWith : '',
      },

      package_info: items.map((d, i) => ({
        package_name: d.title,
        unit: d.quantity,
        unit_weight: d.weight,
      })),
    };

    console.log(reqHeaderData);

    setCreateLoading(true);
    try {
      const req = await fetch('https://api.octaship.com/api/create_shipment', {
        method: 'POST',
        headers: {
          Authorization: accessToken,
        },
        body: JSON.stringify(reqHeaderData),
      });

      const reqData = await req.json();

      if (reqData && reqData.status_code === 200) {
        setShipmentId(reqData.shipping_id);
        setCreateLoading(false);
        toast.success('Shipment was created, now awaiting payment', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setPage(3);
      } else {
        setCreateLoading(false);
        toast.error(reqData.status_desc, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      setCreateLoading(false);
      toast.error('Something went wrong', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }

  async function handleGenerateQuote() {
    try {
      setLoadingFee(true);
      const req = await fetch('https://api.octaship.com/api/fetch_site_info', {
        method: 'GET',
        headers: {
          Authorization: accessToken,
        },
      });

      const reqData = await req.json();

      if (reqData.status_code === 200) {
        setSiteData(reqData.info);
        setStep({
          ...steps,
          currentStep: steps.currentStep + 1,
        });
        setCurrentIndex(4);
        window.scrollTo(0, 0);
      } else {
        sendNotification('Something went wrong! Try again', 'error');
      }
    } catch (err) {
      sendNotification('Something went wrong! Try again', 'error');
    } finally {
      setLoadingFee(false);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const invalidPackages = [];

    if (control._formValues.items.length >= 1) {
      control._formValues.items.forEach((element) => {
        if (!element.title || !element.type || !element.quantity || !element.weight) {
          invalidPackages.push(element);
        }
      });

      if (invalidPackages.length >= 1) {
        toast.error('All packages fields are required.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }
    } else {
      toast.error('Please add a package.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    if (control._formValues.shippingCountry === control._formValues.destinationCountry) {
      toast.error("You can't ship to the same country", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    await handleGenerateQuote();
    // handleChangePage()
    //
    // try {

    //   await new Promise((resolve) => setTimeout(resolve, 500));
    //   reset();
    //   enqueueSnackbar(currentJob ? 'Update success!' : 'Create success!');
    //   router.push(paths.dashboard.job.root);
    //   console.info('DATA', data);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Please provide the Sender’s information
          </Typography>
        </Grid>
      )}
      <Grid xs={12} md={8}>
        <Card className="self-center">
          {!mdUp && <CardHeader title="Sender Details" />}
          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Name</Typography>
              <RHFTextField name="senderName" placeholder="" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Phone Number</Typography>
              <PhoneInput
                country={'ca'}
                onlyCountries={shipmentMethod === 'pickup' ? ['ca'] : ['ca', 'ng']}
                value={senderPhoneNumber}
                isValid={(value, country) => {
                  let regex;

                  switch (country.name) {
                    case 'Canada':
                      regex = /^1\d{10}$/; // Matches 1 followed by 10 digits
                      break;
                    case 'Nigeria':
                      regex = /^(23490|23491|23480|23481|23470|23471)\d{8}$/; // Matches Nigerian numbers with specific starting patterns followed by 7 digits
                      break;
                    default:
                      return false; // If the country is neither Canada nor Nigeria
                  }

                  if (!regex.test(value)) {
                    return 'Invalid number';
                  }

                  return true;
                }}
                onChange={(phone) => {
                  setSenderPhoneNumber(phone);
                }}
                countryCodeEditable={false}
                containerClass="w-full"
                containerStyle={{
                  flex: 1,
                  width: '100%',
                }}
                inputStyle={{
                  width: '100%',
                  height: 50,
                }}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Email Address</Typography>
              <RHFTextField name="senderEmail" placeholder="" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Address</Typography>
              <RHFTextField name="senderAddress" placeholder="" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Address 2</Typography>
              <RHFTextField name="senderAddress2" placeholder="" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Nearest Landmark</Typography>
              <RHFTextField name="senderNearestLandmark" placeholder="" />
            </Stack>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 10,
                width: '100%',
              }}
              className="w-full flex-col md:flex-row"
            >
              <div className="w-full" style={{ flex: 1 }}>
                <Typography variant="subtitle2" className="pb-[10px]">
                  Country
                </Typography>
                <Select
                  value={selectedCountry}
                  isDisabled={isSelectCountDisabled}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 50,
                      minHeight: 35,
                    }),
                  }}
                  onChange={(data) => {
                    setSelectedCountry(data);
                    setSenderState(null);
                    setSenderCity(null);
                  }}
                  options={
                    shipmentMethod === 'pickup'
                      ? [{ value: 'canada', label: 'Canada' }]
                      : [
                          { value: 'nigeria', label: 'Nigeria' },
                          { value: 'canada', label: 'Canada' },
                        ]
                  }
                  formatOptionLabel={(data) => (
                    <div className="country-option flex">
                      <img
                        src={data.value === 'canada' ? CanadaIcon : NigeriaIcon}
                        style={{ width: 25, height: 25, marginRight: 10 }}
                        alt="country-image"
                      />
                      <span>{data.label}</span>
                    </div>
                  )}
                  menuPortalTarget={document.body}
                  className="w-full"
                />
              </div>

              <div className="w-full" style={{ flex: 1 }}>
                <Typography variant="subtitle2" className="pb-[10px]">
                  {selectedCountry?.value === 'canada' ? 'Province' : 'State'}
                </Typography>
                <Select
                  value={senderState}
                  onChange={(data) => {
                    setSenderState(data);
                    setSenderCity(null);
                  }}
                  options={getSenderCountryStates('sender')}
                  menuPortalTarget={document.body}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 50,
                      minHeight: 35,
                    }),
                  }}
                />
              </div>

              <div className="w-full" style={{ flex: 1 }}>
                <Typography variant="subtitle2" className="pb-[10px]">
                  City
                </Typography>
                <Select
                  value={senderCity}
                  onChange={(data) => setSenderCity(data)}
                  options={getSenderCountryCities('sender')}
                  menuPortalTarget={document.body}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 50,
                      minHeight: 35,
                    }),
                  }}
                />
              </div>
            </div>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Postal Code</Typography>
              <RHFTextField name="senderPostalCode" placeholder="" />
            </Stack>
          </Stack>
        </Card>
      </Grid>

      {mdUp && <Grid md={4} />}
      <Grid
        xs={12}
        md={8}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="button"
          variant="contained"
          size="large"
          onClick={() => {
            proceedToNextStep();
          }}
          style={{
            backgroundColor: 'black',
            color: 'white',
          }}
          sx={{ ml: 2 }}
        >
          Continue
        </LoadingButton>
      </Grid>
    </>
  );

  const renderRecieverDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Please provide the Reciever’s information
          </Typography>
        </Grid>
      )}
      <Grid xs={12} md={8}>
        <Card className="self-center">
          {!mdUp && <CardHeader title="Reciever Details" />}
          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Name</Typography>
              <RHFTextField name="recieverName" placeholder="" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Phone Number</Typography>
              <PhoneInput
                country={'ca'}
                onlyCountries={['ca', 'ng']}
                value={recieverPhoneNumber}
                onChange={(phone) => {
                  setRecieverPhoneNumber(phone);
                }}
                isValid={(value, country) => {
                  let regex;
                  switch (country.name) {
                    case 'Canada':
                      regex = /^1\d{10}$/; // Matches 1 followed by 10 digits
                      break;
                    case 'Nigeria':
                      regex = /^(23490|23491|23480|23481|23470|23471)\d{8}$/; // Matches Nigerian numbers with specific starting patterns followed by 7 digits
                      break;
                    default:
                      return false; // If the country is neither Canada nor Nigeria
                  }

                  if (!regex.test(value)) {
                    return 'Invalid number';
                  }

                  return true;
                }}
                countryCodeEditable={false}
                containerClass="w-full"
                containerStyle={{
                  flex: 1,
                  width: '100%',
                }}
                inputStyle={{
                  width: '100%',
                  height: 50,
                }}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Email Address</Typography>
              <RHFTextField name="receiverEmail" type="email" placeholder="" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Address</Typography>
              <RHFTextField name="recieverAddress" placeholder="" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Address 2 (Optional)</Typography>
              <RHFTextField name="receiverAddress2" placeholder="" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Nearest Landmark</Typography>
              <RHFTextField name="recieverNearestLandmark" placeholder="" />
            </Stack>
            <div
              class="flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50"
              role="alert"
            >
              <svg
                class="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                List of prohibited items that cannot be shipped to any country.{' '}
                <Link onClick={() => openRelativeLinkInNewTab('/prohibited-items')} className="font-bold cursor-pointer">
                  View Items
                </Link>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 10,
                width: '100%',
              }}
              className="w-full flex-col md:flex-row"
            >
              <div className="w-full" style={{ flex: 1 }}>
                <Typography variant="subtitle2" className="pb-[10px]">
                  Country
                </Typography>
                <Select
                  value={recieverCountry}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 50,
                      minHeight: 35,
                    }),
                  }}
                  onChange={(data) => {
                    setRecieverCountry(data);
                    setRecieverState(null);
                    setRecieverCity(null);
                  }}
                  options={
                    selectedCountry?.value === 'canada'
                      ? [{ value: 'nigeria', label: 'Nigeria' }]
                      : [{ value: 'canada', label: 'Canada' }]
                  }
                  formatOptionLabel={(data) => (
                    <div className="country-option flex">
                      <img
                        src={data.value === 'canada' ? CanadaIcon : NigeriaIcon}
                        style={{ width: 25, height: 25, marginRight: 10 }}
                        alt="country-image"
                      />
                      <span>{data.label}</span>
                    </div>
                  )}
                  menuPortalTarget={document.body}
                  className="w-full"
                />
              </div>

              <div className="w-full" style={{ flex: 1 }}>
                <Typography variant="subtitle2" className="pb-[10px]">
                  {recieverCountry?.value === 'canada' ? 'Province' : 'State'}
                </Typography>
                <Select
                  value={recieverState}
                  onChange={(data) => {
                    setRecieverState(data);
                    setRecieverCity(null);
                  }}
                  options={getSenderCountryStates('reciever')}
                  menuPortalTarget={document.body}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 50,
                      minHeight: 35,
                    }),
                  }}
                />
              </div>

              <div className="w-full" style={{ flex: 1 }}>
                <Typography variant="subtitle2" className="pb-[10px]">
                  City
                </Typography>
                <Select
                  value={recieverCity}
                  onChange={(data) => setRecieverCity(data)}
                  options={getSenderCountryCities('reciever')}
                  menuPortalTarget={document.body}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 50,
                      minHeight: 35,
                    }),
                  }}
                />
              </div>
            </div>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Postal Code</Typography>
              <RHFTextField name="recieverPostalCode" placeholder="" />
            </Stack>
          </Stack>
        </Card>
      </Grid>
      {mdUp && <Grid md={4} />}
      <Grid
        xs={12}
        md={12}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="button"
          variant="contained"
          size="large"
          onClick={() => {
            setCurrentIndex((prev) => prev - 1);
            setStep({
              ...steps,
              currentStep: steps.currentStep - 1,
            });
            window.scrollTo(0, 0);
          }}
          style={{
            backgroundColor: '#f1f1f1',
            color: 'black',
          }}
          sx={{ ml: 2 }}
        >
          Go Back
        </LoadingButton>
        <LoadingButton
          type="button"
          variant="contained"
          size="large"
          style={{
            backgroundColor: 'black',
            color: 'white',
          }}
          onClick={() => {
            proceedToNextStep();
          }}
          sx={{ ml: 2 }}
        >
          Continue
        </LoadingButton>
      </Grid>
    </>
  );

  const renderOrderSummary = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Review your Shipping information
          </Typography>
        </Grid>
      )}
      <Grid xs={12} md={8}>
        {!mdUp && <CardHeader title="Review your shipping information" />}
        <Card class="mt-6 grow sm:mt-8 lg:mt-0">
          <div class="space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm darkssss:border-gray-700 darkssss:bg-gray-800">
            <h3 class="text-xl font-semibold text-gray-900 darkssss:text-white">
              {shipmentMethod === 'pickup' ? 'PICKUP FROM' : 'SHIPPING FROM'}
            </h3>

            <ol class="relative ms-3 border-s border-gray-200 darkssss:border-gray-700">
              <li class="mb-10 ms-6">
                <span class="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white darkssss:bg-gray-700 darkssss:ring-gray-800">
                  <svg
                    class="h-4 w-4 text-gray-500 darkssss:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"
                    />
                  </svg>
                </span>
                <h4 class="mb-0.5 text-base font-semibold text-gray-900 darkssss:text-white">
                  {summaryData?.senderName}
                </h4>
                {shipmentMethod === 'pickup' && (
                  <p class="text-sm font-normal text-gray-500 darkssss:text-gray-400">
                    {control._formValues.pickupAddress}, {selectedPickupCity?.value}, Ontario,{' '}
                    {selectedCountry?.label}
                  </p>
                )}
                {shipmentMethod !== 'pickup' && (
                  <p class="text-sm font-normal text-gray-500 darkssss:text-gray-400">
                    {summaryData?.senderAddress}, {senderCity?.label}, {senderState?.label}{' '}
                    {selectedCountry?.label}
                  </p>
                )}
                <p class="text-sm font-normal text-gray-500 darkssss:text-gray-400">
                  +{senderPhoneNumber}
                </p>
              </li>
            </ol>

            <h3 class="text-xl font-semibold text-gray-900 darkssss:text-white">DELIVER TO</h3>

            <ol class="relative ms-3 border-s border-gray-200 darkssss:border-gray-700">
              <li class="mb-10 ms-6">
                <span class="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white darkssss:bg-gray-700 darkssss:ring-gray-800">
                  <svg
                    class="h-4 w-4 text-gray-500 darkssss:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"
                    />
                  </svg>
                </span>
                <h4 class="mb-0.5 text-base font-semibold text-gray-900 darkssss:text-white">
                  {summaryData?.recieverName}
                </h4>
                <p class="text-sm font-normal text-gray-500 darkssss:text-gray-400">
                  {summaryData?.recieverAddress}, {recieverCity?.label},{recieverState?.label}{' '}
                  {recieverCountry?.label}
                </p>
                <p class="text-sm font-normal text-gray-500 darkssss:text-gray-400">
                  +{recieverPhoneNumber}
                </p>
              </li>
            </ol>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label className="text-[13px]">PICKUP METHOD</label>
                <p className="text-[17px] text-bold">{shipmentMethod.toUpperCase()}</p>
              </div>
              {shipmentMethod && shipmentMethod === 'pickup' && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className="text-[13px]">COST</label>
                  <p className="text-[17px] text-bold">
                    {paymentCurrency === 'CA' ? '$CAD' : '₦'}
                    {returnPickUpFeeCost()}
                  </p>
                </div>
              )}
              {shipmentMethod && shipmentMethod === 'dropoff' && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className="text-[13px]">Location</label>
                  <p className="text-[17px] text-bold">{dropoffLoc}</p>
                </div>
              )}
              <button
                type="button"
                onClick={() => {
                  return;
                  setModalPage(1);
                  setShowInitialModal(true);
                }}
                class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 darksss:focus:ring-gray-700 darksss:bg-gray-800 darksss:text-gray-400 darksss:border-gray-600 darksss:hover:text-white darksss:hover:bg-gray-700"
              >
                Change
              </button>
            </div>
          </div>
        </Card>

        <Card className="mt-[30px]">
          <div class="space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm darkssss:border-gray-700 darkssss:bg-gray-800">
            <div class="relative overflow-x-auto border-b border-gray-200 darks:border-gray-800">
              <table
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                class="w-full text-left font-medium text-gray-900 darks:text-white md:table-fixed"
              >
                <th> </th>
                <th> </th>
                <th> </th>
                <tbody class="divide-y divide-gray-200 darks:divide-gray-800">
                  {control &&
                    summaryData &&
                    summaryData.items &&
                    summaryData.items.map((element, i) => (
                      <tr>
                        <td class="whitespace-nowrap py-4 md:w-[384px]">
                          <div class="flex items-center gap-4">
                            <p class="">{element.title}</p>
                          </div>
                        </td>

                        <td class="p-4 text-base font-normal text-gray-900 darks:text-white">
                          <div class="flex items-center gap-4">
                            <p class="">Quantity x{element.quantity}</p>
                            <p class="">Weight {element.weight}kg</p>
                          </div>
                        </td>

                        <td class="p-4 text-right text-base font-bold text-gray-900 darks:text-white">
                          <MdOutlineDeleteOutline
                            className="cursor-pointer"
                            onClick={() => {
                              if (summaryData.items.length === 1) {
                                sendNotification('You must have atleast 1 package added', 'error');
                                return;
                              }
                              handleRemoveItem(i);
                            }}
                            color="red"
                            size={22}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        <Card className="mt-[30px]">
          <div class="space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm darkssss:border-gray-700 darkssss:bg-gray-800">
            <div class="mt-4 space-y-6">
              <div class="space-y-4">
                <div class="space-y-2">
                  <dl class="flex items-center justify-between gap-4">
                    <dt class="text-gray-500 darkss:text-gray-400">Shipping fee</dt>
                    <dd class="text-base font-medium text-gray-900 darkss:text-white">
                      {paymentCurrency === 'CA' ? '$' : '₦'}
                      {calculateShippingAndTax().shippingFee}
                    </dd>
                  </dl>

                  <dl class="flex items-center justify-between gap-4">
                    <dt class="text-gray-500 darkss:text-gray-400">
                      Tax (
                      {paymentCurrency && paymentCurrency === 'CA' ? siteData?.cd_tax_fee : '0'})%
                    </dt>
                    <dd class="text-base font-medium text-gray-900 darkss:text-white">
                      {paymentCurrency === 'CA' ? '$' : '₦'}
                      {calculateShippingAndTax().taxFee}
                    </dd>
                  </dl>
                </div>

                <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 darkss:border-gray-700">
                  <dt class="text-lg font-bold text-gray-900 darkss:text-white">Total</dt>
                  <dd class="text-lg font-bold text-gray-900 darkss:text-white">
                    {paymentCurrency === 'CA' ? '$CAD' : '₦'}
                    {calculateShippingAndTax().total}
                  </dd>
                </dl>
              </div>

              <div class="flex items-start sm:items-center">
                <input
                  id="terms-checkbox-2"
                  type="checkbox"
                  value={agreedTerms}
                  onChange={(e) => setAgreedTerms(e.target.checked)}
                  class="h-4 w-4 rounded border-gray-300 bg-gray-100 text-primary-600 focus:ring-2 focus:ring-primary-500 darkss:border-gray-600 darkss:bg-gray-700 darkss:ring-offset-gray-800 darkss:focus:ring-primary-600"
                />
                <label
                  for="terms-checkbox-2"
                  class="ms-2 text-sm font-medium text-gray-900 darkss:text-gray-300"
                >
                  {' '}
                  By clicking, I acknowledge that I have read, understood and agreed to the
                  Octaship’s
                  <a
                    to="/terms"
                    target="_blank"
                    class="text-primary-700 underline hover:no-underline darkss:text-primary-500"
                  >
                    Terms and Conditions
                  </a>{' '}
                </label>
              </div>

              <div class="gap-4 sm:flex sm:items-center">
                <button
                  type="button"
                  onClick={() => {
                    setCurrentIndex((prev) => prev - 1);
                    setStep({
                      ...steps,
                      currentStep: steps.currentStep - 1,
                    });
                    window.scrollTo(0, 0);
                  }}
                  class="w-full rounded-lg  border border-gray-200 bg-white px-5  py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 darkss:border-gray-600 darkss:bg-gray-800 darkss:text-gray-400 darkss:hover:bg-gray-700 darkss:hover:text-white darkss:focus:ring-gray-700"
                >
                  Go back
                </button>

                <button
                  type="button"
                  onClick={() => {
                    if (!agreedTerms) {
                      sendNotification('Please agree to our terms and conditions first', 'error');
                      return;
                    }

                    if (selectedCountry.value !== 'canada' && shipmentMethod === 'pickup') {
                      sendNotification(
                        'Change shipment method! Pickup is only available in Canada',
                        'error'
                      );
                      return;
                    }

                    setShowPaymentModal(true);
                  }}
                  class="mt-4 flex w-full items-center justify-center rounded-lg bg-blue-700  px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300  darkss:bg-primary-600 darkss:hover:bg-primary-700 darkss:focus:ring-primary-800 sm:mt-0"
                >
                  Confirm and Pay
                </button>
              </div>
            </div>
          </div>
        </Card>
      </Grid>
      {mdUp && <Grid md={4} />}
    </>
  );

  const renderProperties = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Shipment Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Shipment location, type...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Shipment Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Shipping Country</Typography>
              <RHFAutocomplete
                name="shippingCountry"
                placeholder="Location"
                options={countries.map((option) => option.label)}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => {
                  const { code, label, phone } = countries.filter(
                    (country) => country.label === option
                  )[0];

                  if (!label) {
                    return null;
                  }

                  return (
                    <li {...props} key={label}>
                      <Iconify
                        key={label}
                        icon={`circle-flags:${code.toLowerCase()}`}
                        width={28}
                        sx={{ mr: 1 }}
                      />
                      {label} ({code})
                    </li>
                  );
                }}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      label={option}
                      size="small"
                      color="info"
                      variant="soft"
                    />
                  ))
                }
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Destination Country</Typography>
              <RHFAutocomplete
                name="destinationCountry"
                placeholder="Location"
                options={countries.map((option) => option.label)}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => {
                  const { code, label, phone } = countries.filter(
                    (country) => country.label === option
                  )[0];

                  if (!label) {
                    return null;
                  }

                  return (
                    <li {...props} key={label}>
                      <Iconify
                        key={label}
                        icon={`circle-flags:${code.toLowerCase()}`}
                        width={28}
                        sx={{ mr: 1 }}
                      />
                      {label} ({code})
                    </li>
                  );
                }}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      label={option}
                      size="small"
                      color="info"
                      variant="soft"
                    />
                  ))
                }
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="subtitle2">Shipment Method</Typography>
              <Controller
                name="shippingMethod"
                control={control}
                render={({ field }) => (
                  <Box gap={2} display="grid" gridTemplateColumns="repeat(2, 1fr)">
                    {itemsToRender().map((item) => (
                      <Paper
                        component={ButtonBase}
                        variant="outlined"
                        key={item.label}
                        onClick={() => field.onChange(item.label)}
                        sx={{
                          p: 2.5,
                          borderRadius: 1,
                          typography: 'subtitle2',
                          flexDirection: 'column',
                          ...(item.label === field.value && {
                            borderWidth: 2,
                            borderColor: 'text.primary',
                          }),
                        }}
                      >
                        {item.icon}
                        {item.label}
                      </Paper>
                    ))}
                  </Box>
                )}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Comment (Optional)</Typography>
              <RHFTextField name="comment" placeholder="" />
            </Stack>
          </Stack>
        </Card>
      </Grid>
      {mdUp && <Grid md={4} />}
      <Grid
        xs={12}
        md={8}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="button"
          variant="contained"
          size="large"
          onClick={() => setCurrentIndex((prev) => prev - 1)}
          style={{
            backgroundColor: '#f1f1f1',
            color: 'black',
          }}
          sx={{ ml: 2 }}
        >
          Go Back
        </LoadingButton>
        <LoadingButton
          type="button"
          variant="contained"
          size="large"
          style={{
            backgroundColor: 'black',
            color: 'white',
          }}
          onClick={() => setCurrentIndex(4)}
          sx={{ ml: 2 }}
        >
          Continue
        </LoadingButton>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid
        xs={12}
        md={8}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          disabled={validateForm()}
          style={{
            backgroundColor: quoteGenerated ? 'green' : 'black',
          }}
          loading={loadingFee}
          sx={{ ml: 2 }}
        >
          {quoteGenerated ? 'Proceed' : 'Calculate Fee'}
        </LoadingButton>
      </Grid>
    </>
  );

  const renderShipmentItems = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Packages
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Enter details about the items you want to ship
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Packages" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <AddShipmentItems siteData={siteData} totalAmount={totalAmount} />
          </Stack>
        </Card>
      </Grid>
      {mdUp && <Grid md={4} />}
      <Grid
        xs={12}
        md={8}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <LoadingButton
          type="button"
          variant="contained"
          size="large"
          onClick={() => setCurrentIndex((prev) => prev - 1)}
          style={{
            backgroundColor: '#f1f1f1',
            color: 'black',
          }}
          sx={{ ml: 2 }}
        >
          Go Back
        </LoadingButton>
        <LoadingButton
          type="button"
          variant="contained"
          loading={loadingFee}
          size="large"
          style={{
            backgroundColor: 'black',
            color: 'white',
          }}
          onClick={() => proceedToNextStep()}
          sx={{ ml: 2 }}
        >
          Continue
        </LoadingButton>
      </Grid>
    </>
  );

  const renderCheckout = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Make Payment
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Select payment method...
          </Typography>
        </Grid>
      )}
      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Make Payment" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={2}>
              <Typography variant="subtitle2">Select payment method</Typography>
              <Controller
                name="paymentMethod"
                control={control}
                render={({ field }) => (
                  <Box gap={2} display="grid" gridTemplateColumns="repeat(2, 1fr)">
                    {[
                      control && control._formValues.shippingCountry === 'Nigeria'
                        ? {
                            label: 'Bank Transfer',
                            icon: (
                              <Iconify icon="solar:clock-circle-bold" width={32} sx={{ mb: 2 }} />
                            ),
                          }
                        : control && control._formValues.shippingCountry === 'Canada'
                        ? {
                            label: 'Email (Interact)',
                            icon: (
                              <Iconify icon="solar:wad-of-money-bold" width={32} sx={{ mb: 2 }} />
                            ),
                          }
                        : [],
                    ].map((item) => (
                      <Paper
                        component={ButtonBase}
                        variant="outlined"
                        key={item.label}
                        onClick={() => field.onChange(item.label)}
                        sx={{
                          p: 2.5,
                          borderRadius: 1,
                          typography: 'subtitle2',
                          flexDirection: 'column',
                          ...(item.label === field.value && {
                            borderWidth: 2,
                            borderColor: 'text.primary',
                          }),
                        }}
                      >
                        {item.icon}
                        {item.label}
                      </Paper>
                    ))}
                  </Box>
                )}
              />
            </Stack>
            {control._formValues.shippingCountry === 'Canada' &&
              control._formValues.paymentMethod === 'Email (Interact)' && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Email To Send From</Typography>
                  <RHFTextField name="emailToSendWith" placeholder="" />
                </Stack>
              )}
          </Stack>
        </Card>
        <Grid
          xs={12}
          md={8}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <LoadingButton
            type="button"
            onClick={() => {
              setQuoteGenerated(false);
              setSiteData(null);
              setPage(1);
            }}
            variant="contained"
            size="large"
            sx={{ ml: 2 }}
          >
            Previous
          </LoadingButton>
          <LoadingButton
            type="button"
            disabled={
              !control._formValues.paymentMethod
                ? true
                : !!(
                    control._formValues.shippingCountry === 'Canada' &&
                    control._formValues.paymentMethod === 'Email (Interact)' &&
                    !control._formValues.emailToSendWith
                  )
            }
            onClick={() => {
              if (!control._formValues.paymentMethod) return;
              createShipment();
            }}
            variant="contained"
            size="large"
            sx={{ ml: 2 }}
            loading={createLoading}
          >
            Proceed
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );

  const renderPayWithInteract = (
    <>
      {mdUp && (
        <Grid md={4}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ mb: 0.5 }}>
              Send your payment
            </Typography>
            <img src={InteractPNG} width={70} height={55} />
          </div>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            For your shipment to be processed you need to send <strong>$CAD {totalAmount}</strong>{' '}
            to this details below...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 20,
              }}
            >
              <Typography variant="h6" sx={{ mb: 0.5 }}>
                Send your payment
              </Typography>
              <img src={InteractPNG} width={70} height={55} />
            </div>
          )}

          {paymentModalPage === 1 && (
            <>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Email</Typography>
                  <div
                    style={{
                      backgroundColor: 'white',
                      width: '100%',
                      border: '1px solid gray',
                      padding: 13,
                      borderRadius: 10,
                    }}
                  >
                    {siteData ? siteData.canada_transfer_email : 'N/A'}
                  </div>
                </Stack>
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Payment Reference (put in narration)</Typography>
                  <div
                    style={{
                      backgroundColor: 'white',
                      width: '100%',
                      border: '1px solid gray',
                      padding: 13,
                      borderRadius: 10,
                    }}
                  >
                    {shipmentId}
                  </div>
                </Stack>
                <button
                  type="button"
                  variant="outline"
                  size="large"
                  sx={{ ml: 2 }}
                  onClick={() => setPaymentModalPage(2)}
                >
                  I have sent the money
                </button>
              </Stack>
              <button
                type="button"
                variant="outline"
                size="large"
                sx={{ ml: 2 }}
                onClick={() => setPaymentModalPage(2)}
              >
                I have sent the money
              </button>
            </>
          )}

          {paymentModalPage === 2 && (
            <>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">Email you send with</Typography>
                <RHFTextField
                  name="emailSendWith"
                  helperText={'The email you made the transfer from'}
                  placeholder=""
                />
              </Stack>
            </>
          )}
        </Card>
        <Grid
          xs={12}
          md={8}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <LoadingButton
            type="button"
            variant="contained"
            style={{ backgroundColor: 'red' }}
            size="large"
            loading={cancelLoading}
            sx={{ ml: 2 }}
            onClick={() => handleCancelShipment()}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            type="button"
            variant="contained"
            size="large"
            sx={{ ml: 2 }}
            onClick={() => setPage(4)}
          >
            I'have sent the money
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );

  const renderPayWithBankTransfer = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Send your payment
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            For your shipment to be processed you need to send <strong>₦{totalAmount}</strong> to
            this account below...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && (
            <>
              <CardHeader title="Payment Info" />
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary', paddingLeft: 3, marginTop: 2 }}
              >
                For your shipment to be processed you need to send <strong>₦{totalAmount}</strong>{' '}
                to this account below...
              </Typography>
            </>
          )}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Bank</Typography>
              <div
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid gray',
                  padding: 13,
                  borderRadius: 10,
                }}
              >
                {siteData ? siteData.ng_bank_name : ''}
              </div>
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Bank Name</Typography>
              <div
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid gray',
                  padding: 13,
                  borderRadius: 10,
                }}
              >
                {siteData ? siteData.ng_acct_name : ''}
              </div>
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Account Number</Typography>
              <div
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid gray',
                  padding: 13,
                  borderRadius: 10,
                }}
              >
                {siteData ? siteData.ng_acct_num : ''}
              </div>
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Payment Reference (put in narration)</Typography>
              <div
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid gray',
                  padding: 13,
                  borderRadius: 10,
                }}
              >
                {shipmentId}
              </div>
            </Stack>
          </Stack>
        </Card>
        <Grid
          xs={12}
          md={8}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <LoadingButton
            type="button"
            variant="contained"
            style={{ backgroundColor: 'red' }}
            size="large"
            loading={cancelLoading}
            sx={{ ml: 2 }}
            onClick={() => handleCancelShipment()}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            type="button"
            onClick={() => setPage(4)}
            variant="contained"
            size="large"
            sx={{ ml: 2 }}
          >
            I'have sent the money
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );

  const renderPaySuccess = (
    <section className="bjk:bg-gray-900 mx-auto">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <div className="w-full bg-white rounded-lg shadow bjk:border md:mt-0 sm:max-w-md xl:p-0 bjk:bg-gray-800 bjk:border-gray-700">
          <div
            className="p-6 space-y-4 md:space-y-6 sm:p-8 d-flex"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Lottie animationData={Animation} loop style={{ height: 120, width: 120 }} />
            <h1 className="text-xl font-bold leading-tight text-center tracking-tight text-gray-900 md:text-2xl bjk:text-white">
              Shipment has been created successfully
            </h1>
            <p className="text-center">
              Your shipment will be processed as soon as your payment is recieved.
            </p>
            <button
              onClick={() => router.push(`/shipment/track/${shipmentId}`)}
              type="button"
              className="text-white w-full bg-gradient-to-r from-[#1E58DE] via-[#1E58DE] to-[#1E58DE] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-[#1E58DE]-300 bjk:focus:ring-purple-800 shadow-lg shadow-[#1E58DE]/50 d[#1E58DE]-lg [#280134]w-[#1E58DE]-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              View order
            </button>
          </div>
        </div>
      </div>
    </section>
  );

  if (!control) {
    return null;
  }

  useEffect(() => {
    async function getData() {
      try {
        setFirstLoading(true);
        const req = await fetch('https://api.octaship.com/api/fetch_site_info', {
          method: 'GET',
          headers: {
            Authorization: accessToken,
          },
        });

        const reqData = await req.json();

        if (reqData.status_code === 200) {
          setSiteData(reqData.info);
        }
      } catch (err) {
        alert('Something went wrong! Please refresh');
      } finally {
        setFirstLoading(false);
      }
    }

    getData();
  }, []);

  if (firstLoading) {
    return <LoadingScreen />;
  }

  return (
    <FormProvider methods={methods} onSubmit={null}>
      <Modal
        title={
          modalPage === 1
            ? 'Shipment Method'
            : modalPage === 2
            ? 'Dropoff Location'
            : modalPage === 4
            ? 'Pickup Details'
            : 'Payment Currency'
        }
        centered
        open={showInitialModal}
        onOk={() => setShowInitialModal(false)}
        zIndex={2000}
        maskClosable={false}
        onCancel={() => {
          return;
          if (page === 1 && !shipmentMethod) return;
          if (page === 2 && !dropoffLoc) setShowInitialModal(false);

          setShowInitialModal(false);
        }}
        footer={[null]}
      >
        {modalPage === 1 && (
          <div className="w-full px-5 pb-10 pt-[15px]">
            <h2 className="text-[16px]">Select a preferred option</h2>

            <div
              onClick={() => {
                setShipmentMethod('dropoff');
                setModalPage(2);
              }}
              className="shadow-lg cursor-pointer mt-[15px] mb-[20px] rounded-md py-[10px] border border-blue-700 w-[95%] p-[13px]"
            >
              <h3 className="text-[13px] text-blue-700 font-bold">DROP OFF</h3>
              <p className="text-[15px] text-gray-600">Drop off your items at Octaship warehouse</p>
            </div>

            <div
              onClick={() => {
                setShipmentMethod('pickup');
                setModalPage(4);
              }}
              className="shadow-lg mt-[20px] cursor-pointer rounded-md py-[10px] border border-blue-700 w-[95%] p-[13px]"
            >
              <h3 className="text-[13px] text-blue-600 font-bold">PICKUP</h3>
              <p className="text-[15px] text-gray-600">
                A dispatch rider will pick up your items at your preferred location. (Only available
                within Ontario Canada 🇨🇦)
              </p>
            </div>
          </div>
        )}

        {modalPage === 2 && (
          <div className="w-full px-5 pb-10 pt-[15px]">
            <h2 className="text-[16px]">Select dropoff location</h2>
            {siteData && siteData.ng_dropoff_location && (
              <div
                onClick={() => {
                  setDropOffLoc(siteData.ng_dropoff_location);
                  setPaymentCurrency('NG');
                  setIsSelectedCountDisabled(true);
                  setSelectedCountry({ value: 'nigeria', label: 'Nigeria' });
                  setShowInitialModal(false);
                }}
                className="shadow-lg cursor-pointer mt-[20px] rounded-md py-[10px] border border-blue-700 w-[95%] p-[13px]"
              >
                <h3 className="text-[13px] text-blue-600 font-bold">
                  Location (Nigeria 🇳🇬 ) - Address
                </h3>
                <p className="text-[15px] text-gray-600">{siteData.ng_dropoff_location}</p>
              </div>
            )}
            {siteData && siteData.cd_dropoff_location && (
              <div
                onClick={() => {
                  setDropOffLoc(siteData.cd_dropoff_location);
                  setPaymentCurrency('CA');
                  setIsSelectedCountDisabled(true);
                  setSelectedCountry({ value: 'canada', label: 'Canada' });
                  setShowInitialModal(false);
                }}
                className="shadow-lg cursor-pointer mt-[20px] rounded-md py-[10px] border border-blue-700 w-[95%] p-[13px]"
              >
                <h3 className="text-[13px] text-blue-600 font-bold">
                  Location (Canada 🇨🇦 ) - Address
                </h3>
                <p className="text-[15px] text-gray-600">{siteData.cd_dropoff_location}</p>
              </div>
            )}
          </div>
        )}

        {modalPage === 3 && (
          <div className="w-full px-5 pb-10 pt-[15px]">
            <h2 className="text-[16px]">Select the currency you are making the payment with</h2>
            <div
              onClick={() => {
                setPaymentCurrency('CA');
                setShowInitialModal(false);
              }}
              className="shadow-lg cursor-pointer mb-[20px] mt-[15px] rounded-md py-[10px] border border-blue-700 w-[95%] p-[13px]"
            >
              <h3 className="text-[13px] font-bold">Canadian Dollar ($CA)</h3>
            </div>
            <div
              onClick={() => {
                setPaymentCurrency('NG');
                setShowInitialModal(false);
              }}
              className="shadow-lg cursor-pointer rounded-md py-[10px] border border-blue-700 w-[95%] p-[13px]"
            >
              <h3 className="text-[13px] font-bold">Nigerian Naira (NGN)</h3>
            </div>
          </div>
        )}

        {modalPage === 4 && (
          <div className="w-full px-5 pb-10 pt-[15px]">
            <Stack spacing={1.5} style={{ marginBottom: 10 }}>
              <Typography variant="subtitle2">City</Typography>
              <Select
                value={selectedPickupCity}
                placeholder="Select city"
                onChange={(data) => {
                  setSelectedPickupCity(data);
                }}
                options={pickUpLocations.map((l, i) => ({
                  value: l.city,
                  label: l.city,
                }))}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 50,
                    minHeight: 35,
                  }),
                }}
              />
            </Stack>
            <Stack spacing={1.5} style={{ marginBottom: 10 }}>
              <Typography variant="subtitle2">Address</Typography>
              <RHFTextField name="pickupAddress" placeholder="" type="text" />
            </Stack>
            <Stack
              spacing={1.5}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingButton
                type="button"
                onClick={() => {
                  if (!selectedPickupCity) {
                    sendNotification('Pickup city is required', 'error');
                    return;
                  }
                  if (!control._formValues.pickupAddress) {
                    sendNotification('Pickup address is required', 'error');
                    return;
                  }
                  setIsSelectedCountDisabled(true);
                  setSelectedCountry({ value: 'canada', label: 'Canada' });
                  setPaymentCurrency('CA');
                  setShowInitialModal(false);
                }}
                variant="contained"
                size="large"
                style={{ width: '80%', marginTop: 20 }}
              >
                Continue
              </LoadingButton>
              <LoadingButton
                type="button"
                variant="contained"
                style={{ backgroundColor: 'white', color: 'black', width: '80%' }}
                size="large"
                sx={{ ml: 2 }}
                onClick={() => {
                  setShipmentMethod('');
                  setModalPage(1);
                }}
              >
                Go back
              </LoadingButton>
            </Stack>
          </div>
        )}
      </Modal>

      <Modal
        title={paymentModalPage === 1 ? 'Make payment' : 'One last step...'}
        centered={true}
        open={showPaymentModal}
        onOk={() => setShowPaymentModal(false)}
        maskClosable={false}
        closable={true}
        onCancel={() => {
          if (submitLoading) return;
          setShowPaymentModal(false);
          setPaymentModalPage(1);
        }}
        footer={[null]}
        zIndex={2000}
      >
        <div className="w-full px-5 pb-10 pt-[15px]">
          <div className="flex gap-[5px]" style={{ alignItems: 'center' }}>
            <h2 className="text-[16px]">
              {paymentCurrency === 'CA' ? 'Pay via Interact' : 'Pay via Bank Transfer'}
            </h2>
            <img
              src={paymentCurrency === 'CA' ? InteractPNG : BankIcon}
              width={paymentCurrency === 'CA' ? 45 : 25}
              height={paymentCurrency === 'CA' ? 45 : 25}
            />
          </div>
          {paymentModalPage === 1 && (
            <p>
              For your shipment to be created you need to send{' '}
              <strong>
                {paymentCurrency === 'CA' ? '$CAD' : '₦'}
                {calculateShippingAndTax().total}
              </strong>{' '}
              {paymentCurrency === 'CA'
                ? 'to this email below...'
                : 'to this bank account below...'}
            </p>
          )}
          {paymentModalPage === 2 && (
            <p className="pt-[13px] pb-[10px]">
              Lets make sure we confirm your payment and process your shipment ASAP
            </p>
          )}

          {paymentModalPage === 1 && (
            <>
              <Stack spacing={3} sx={{ p: 3 }}>
                {paymentCurrency === 'CA' && (
                  <>
                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">Email</Typography>
                      <div
                        style={{
                          backgroundColor: 'white',
                          width: '100%',
                          border: '1px solid gray',
                          padding: 13,
                          borderRadius: 10,
                        }}
                      >
                        {siteData ? siteData.canada_transfer_email : 'N/A'}
                      </div>
                    </Stack>
                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">
                        Payment Reference (put in narration)
                      </Typography>
                      <div
                        style={{
                          backgroundColor: 'white',
                          width: '100%',
                          border: '1px solid gray',
                          padding: 13,
                          borderRadius: 10,
                        }}
                      >
                        49459g8f
                      </div>
                    </Stack>
                  </>
                )}

                {paymentCurrency !== 'CA' && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 30,
                      }}
                    >
                      <Stack style={{ flex: 1 }} spacing={1.5}>
                        <Typography variant="subtitle2">Account Name</Typography>
                        <div
                          style={{
                            backgroundColor: 'white',
                            width: '100%',
                            border: '1px solid gray',
                            padding: 13,
                            borderRadius: 10,
                          }}
                        >
                          {siteData ? siteData.ng_acct_name : 'N/A'}
                        </div>
                      </Stack>
                      <Stack spacing={1.5} style={{ flex: 1 }}>
                        <Typography variant="subtitle2">Account Number</Typography>
                        <div
                          style={{
                            backgroundColor: 'white',
                            width: '100%',
                            border: '1px solid gray',
                            padding: 13,
                            borderRadius: 10,
                          }}
                        >
                          {siteData ? siteData.ng_acct_num : 'N/A'}
                        </div>
                      </Stack>
                    </div>
                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">Bank Name</Typography>
                      <div
                        style={{
                          backgroundColor: 'white',
                          width: '100%',
                          border: '1px solid gray',
                          padding: 13,
                          borderRadius: 10,
                        }}
                      >
                        {siteData ? siteData.ng_bank_name : 'N/A'}
                      </div>
                    </Stack>
                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">
                        Payment Reference (put in narration)
                      </Typography>
                      <div
                        style={{
                          backgroundColor: 'white',
                          width: '100%',
                          border: '1px solid gray',
                          padding: 13,
                          borderRadius: 10,
                        }}
                      >
                        49459g8f4f8fd
                      </div>
                    </Stack>
                  </>
                )}
                <LoadingButton
                  type="button"
                  onClick={() => setPaymentModalPage(2)}
                  variant="contained"
                  size="large"
                  sx={{ ml: 2 }}
                >
                  I have sent the money
                </LoadingButton>
              </Stack>
            </>
          )}

          {paymentModalPage === 2 && (
            <>
              {paymentCurrency === 'CA' ? (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Email you send from</Typography>
                  <RHFTextField
                    name="paymentEmail"
                    helperText={'Provide the email you made the transfer from'}
                    placeholder=""
                    type="email"
                  />
                </Stack>
              ) : (
                <>
                  <Stack spacing={1.5} style={{ marginBottom: 10 }}>
                    <Typography variant="subtitle2">Account Name</Typography>
                    <RHFTextField
                      name="paymentAccountName"
                      helperText={'Provide the account name you made the transfer from'}
                      placeholder=""
                      type="text"
                    />
                  </Stack>
                  <Stack spacing={1.5} style={{ marginBottom: 10 }}>
                    <Typography variant="subtitle2">Account Number</Typography>
                    <RHFTextField
                      name="paymentAccountNumber"
                      helperText={'Provide the account number you made the transfer from'}
                      placeholder=""
                      type="number"
                    />
                  </Stack>
                  <Stack spacing={1.5} style={{ marginBottom: 10 }}>
                    <Typography variant="subtitle2">Bank Name</Typography>
                    <RHFTextField
                      name="paymentBankName"
                      helperText={'Provide the bank name you made the transfer from'}
                      placeholder=""
                      type="text"
                    />
                  </Stack>
                </>
              )}
              <Stack spacing={1.5}>
                <LoadingButton
                  type="button"
                  onClick={() => {
                    if (paymentCurrency === 'CA') {
                      if (!control._formValues.paymentEmail) {
                        sendNotification(
                          'Please provide the email you made the transfer from',
                          'error'
                        );
                        return;
                      }
                    } else {
                      if (
                        !control._formValues.paymentAccountName ||
                        !control._formValues.paymentAccountNumber ||
                        !control._formValues.paymentBankName
                      ) {
                        sendNotification('All fields are required', 'error');
                        return;
                      }
                    }

                    createShipment();
                  }}
                  variant="contained"
                  size="large"
                  sx={{ ml: 2 }}
                  loading={submitLoading}
                >
                  Submit
                </LoadingButton>
                <LoadingButton
                  type="button"
                  variant="contained"
                  style={{ backgroundColor: 'white', color: 'black' }}
                  size="large"
                  sx={{ ml: 2 }}
                  onClick={() => setPaymentModalPage(1)}
                >
                  Go back
                </LoadingButton>
              </Stack>
            </>
          )}
        </div>
      </Modal>

      {mdUp && (
        <div className="max-w-2xl mx-auto mb-[40px] px-4 md:px-0">
          <ul aria-label="Steps" className="items-center text-gray-600 font-medium md:flex">
            {steps.stepsItems.map((item, idx) => (
              <li
                aria-current={steps.currentStep == idx + 1 ? 'step' : false}
                className="flex-1 last:flex-none flex gap-x-2 md:items-center"
              >
                <div className="flex items-center flex-col gap-x-2">
                  <div
                    className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${
                      steps.currentStep > idx + 1
                        ? 'bg-blue-700 border-indigo-600'
                        : '' || steps.currentStep == idx + 1
                        ? 'border-blue-700'
                        : ''
                    }`}
                  >
                    <span
                      className={` ${
                        steps.currentStep > idx + 1
                          ? 'hidden'
                          : '' || steps.currentStep == idx + 1
                          ? 'text-blue-700'
                          : ''
                      }`}
                    >
                      {idx + 1}
                    </span>
                    {steps.currentStep > idx + 1 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    ) : (
                      ''
                    )}
                  </div>
                  <hr
                    className={`h-12 border md:hidden ${
                      idx + 1 == steps.stepsItems.length
                        ? 'hidden'
                        : '' || steps.currentStep > idx + 1
                        ? 'border-blue-700'
                        : ''
                    }`}
                  />
                </div>
                <div className="h-8 flex items-center md:h-auto">
                  <h3 className={`text-sm ${steps.currentStep == idx + 1 ? 'text-blue-700' : ''}`}>
                    {item}
                  </h3>
                </div>
                <hr
                  className={`hidden mr-2 w-full border md:block ${
                    idx + 1 == steps.stepsItems.length
                      ? 'hidden'
                      : '' || steps.currentStep > idx + 1
                      ? 'border-indigo-700'
                      : ''
                  }`}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      <Grid container spacing={3}>
        {(() => {
          if (page === 1) {
            return (
              <>
                {currentIndex === 1
                  ? renderDetails
                  : currentIndex === 2
                  ? renderRecieverDetails
                  : currentIndex === 3
                  ? renderShipmentItems
                  : renderOrderSummary}
              </>
            );
          }

          if (page === 2) {
            return renderPaySuccess;
          }
        })()}
      </Grid>
    </FormProvider>
  );
}

JobNewEditForm.propTypes = {
  currentJob: PropTypes.object,
};
