import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';

import { LoadingScreen } from 'src/components/loading-screen';

import { OrderDetailsView } from 'src/sections/order/view';
import DataNotFound from 'src/sections/order/not-found';

// ----------------------------------------------------------------------

export default function OrderDetailsPage() {
  const params = useParams();
  const { user } = useAuthContext()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)

  const { id } = params;

  const accessToken = sessionStorage.getItem("accessToken");

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true)
      const req = await fetch("https://api.octaship.com/api/track_shipment", {
      method: "POST",
      headers: {
        "Authorization": user.token
      },
      body: JSON.stringify({
        shipping_id: id
      })
    })
    
    const reqData = await req.json();

    if(reqData && reqData.status_code === 200){
      setData(reqData.info)
      setLoading(false)
    }else {
      setLoading(false)
    }
    }catch(err){
      setLoading(false)
    }
  }
    
    fetchData()

 }, [])
 


 if(loading){
  return (
    <>
      <Helmet>
        <title>Loading</title>
      </Helmet>
        <LoadingScreen /> 
    </>
  )
}


if(!data || data === null && !loading){
  return (
    <>
      <Helmet>
        <title>Shipment Not Found</title>
      </Helmet>
   <DataNotFound />
 </>
  )
}

if(data && !loading){

  return (
    <>
      <Helmet>
        <title> Shipment: Details</title>
      </Helmet>

      <OrderDetailsView data={data} />
    </>
  );
}
}
