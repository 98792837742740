export const countries = [
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  { code: '', label: '', phone: '' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
    index: 0
  },
  { code: 'NG', label: 'Nigeria', phone: '234', index: 1 }
];
