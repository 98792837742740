import { useEffect, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { fCurrency } from 'src/utils/format-number';

import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function AddShipmentItems({ totalAmount }) {
  const { control, setValue, watch, resetField } = useFormContext();
  const { user } = useAuthContext()


  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });
  
  const values = watch();
  


  const itemsType = [
    {
        "id": "document",
        "name": "Document"
    },
    {
        "id": "non-document",
        "name": "Non - Document"
    },
  ]

  useEffect(() => {
    setValue('totalAmount', totalAmount);
  }, [setValue, totalAmount]);

  

  const handleAdd = () => {
    append({
      title: '',
      type: '',
      quantity: 1,
      weight: 0,
    });
  };

  const handleRemove = (index) => {
    remove(index);
  };

  const handleClearService = useCallback(
    (index) => {
      resetField(`items[${index}].quantity`);
      resetField(`items[${index}].weight`);
      resetField(`items[${index}].type`);
    },
    [resetField]
  );

  const handleSelectService = useCallback(
    (index, option) => {
     
    },
    []
  );

  const handleChangeQuantity = useCallback(
    (event, index) => {
      setValue(`items[${index}].quantity`, Number(event.target.value));
    },
    [setValue]
  );

  const handleChangeWeight = useCallback(
    (event, index) => {
      setValue(`items[${index}].weight`, Number(event.target.value));
    },
    [setValue]
  );

  function openRelativeLinkInNewTab(relativeUrl) {
    const absoluteUrl = new URL(relativeUrl, window.location.origin);
    window.open(absoluteUrl.href, '_blank').focus();
  }


  const renderTotal = (
    <Stack
      spacing={2}
      alignItems="flex-end"
      sx={{ mt: 3, textAlign: 'right', typography: 'body2' }}
    >
      {control && control._formValues.shippingMethod === "Pickup" && 
         <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Pickup</Box>
        <Box
          sx={{
            width: 160,
            ...(values.shipping && { color: 'error.main' }),
          }}
        >
          Additional cost may apply
        </Box>
      </Stack>}
      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Shipping</Box>
        <Box
          sx={{
            width: 160,
            ...(totalAmount && { color: 'error.main' }),
          }}
        >
          {totalAmount ? `- ${totalAmount}` : '-'}
        </Box>
      </Stack>
      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Discount</Box>
        <Box
          sx={{
            width: 160,
            ...(values.discount && { color: 'error.main' }),
          }}
        >
          {'-'}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Taxes</Box>
        <Box sx={{ width: 160 }}>{values.taxes ? fCurrency(values.taxes) : '-'}</Box>
      </Stack>

      <Stack direction="row" sx={{ typography: 'subtitle1' }}>
        <Box>Total</Box>
        <Box sx={{ width: 160 }}>{(`${control._formValues.shippingCountry && control._formValues.shippingCountry === "Nigeria" ? '₦' : control._formValues.shippingCountry && control._formValues.shippingCountry === "Canada" ? '$CAD' : ''} ${totalAmount}`) || '-'}</Box>
      </Stack>
    </Stack>
  );

  return (
    <Box sx={{ p: 3 }}>
      <h2 className='text-blue-600 text-[11px] pb-[60px] cursor-pointer'>SEE LIST OF <span onClick={() => openRelativeLinkInNewTab("/prohibited-items")} className='font-bold'>PROHIBITED & RESTRICTED ITEMS.</span></h2>
      <div className="mb-[10px]" />
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Items:
      </Typography>
      <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
        {fields.map((item, index) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
              <RHFTextField
                size="small"
                name={`items[${index}].title`}
                label="Title"
                InputLabelProps={{ shrink: true }}
              />

              <RHFSelect
                name={`items[${index}].type`}
                size="small"
                label="Type"
                InputLabelProps={{ shrink: true }}
                sx={{
                  maxWidth: { md: 160 },
                }}
              >
                <MenuItem
                  value=""
                  onClick={() => handleClearService(index)}
                  sx={{ fontStyle: 'italic', color: 'text.secondary' }}
                >
                  None
                </MenuItem>

                <Divider sx={{ borderStyle: 'dashed' }} />

                {itemsType.map((service) => (
                  <MenuItem
                    key={service.id}
                    value={service.name}
                    onClick={() => handleSelectService(index, service.name)}
                  >
                    {service.name}
                  </MenuItem>
                ))}
              </RHFSelect>

              <RHFTextField
                size="small"
                type="number"
                name={`items[${index}].quantity`}
                label="Quantity"
                placeholder="0"
                onChange={(event) => handleChangeQuantity(event, index)}
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 96 } }}
              />

              <RHFTextField
                size="small"
                type="number"
                name={`items[${index}].weight`}
                label="Weight"
                placeholder="KG"
                onChange={(event) => handleChangeWeight(event, index)}
                sx={{ maxWidth: { md: 96 } }}
              />
            </Stack>

            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
              onClick={() => handleRemove(index)}
            >
              Remove
            </Button>
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
      >
        <Button
          size="small"
          color="primary"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          Add Item
        </Button>
      </Stack>
    </Box>
  );
}
