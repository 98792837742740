import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import ListItemText from '@mui/material/ListItemText';

import Scrollbar from 'src/components/scrollbar';

// ----------------------------------------------------------------------

export default function OrderDetailsItems({ data }) {

  const packages = JSON.parse(data.packages_details)


  const renderTotal = (
    <Stack
      spacing={2}
      alignItems="flex-end"
      sx={{ my: 3, textAlign: 'right', typography: 'body2' }}
    >
      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Shipping</Box>
        <Box
          sx={{
            width: 160,
          }}
        >
          {data.shipping_fee}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Discount</Box>
        <Box
          sx={{
            width: 160,
          }}
        >
          -
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Taxes</Box>
        <Box sx={{ width: 160 }}>-</Box>
      </Stack>

      <Stack direction="row" sx={{ typography: 'subtitle1' }}>
        <Box>Total</Box>
        <Box sx={{ width: 160 }}>{data.payment_method === "bank_transfer_ng" ? "₦" : "$CAD"}{data.shipping_fee}</Box>
      </Stack>
    </Stack>
  );

  //
  return (
    <Card>
      <CardHeader
        title="Details"
    
      />

      <Stack
        sx={{
          px: 3,
        }}
      >
        <Scrollbar>
         {/* MAP THE ITEMS */}
          {packages && packages.length >= 1 && packages.map((item, i) => (
            <Stack
              key={i}
              direction="row"
              alignItems="center"
              sx={{
                py: 3,
                minWidth: 640,
                borderBottom: (theme) => `dashed 2px ${theme.palette.background.neutral}`,
              }}
            >
              <ListItemText
                primary={item.package_name}
                secondary=""
                primaryTypographyProps={{
                  typography: 'body2',
                }}
                secondaryTypographyProps={{
                  component: 'span',
                  color: 'text.disabled',
                  mt: 0.5,
                }}
              />
              <Box sx={{ typography: 'body2' }}>x{item.unit}</Box>
              <Box sx={{ width: 110, textAlign: 'right', typography: 'subtitle2' }}>
                {item.unit_weight}kg
              </Box>
              <Box sx={{ typography: 'body2', textAlign: "right", marginLeft: 10 }} />
            </Stack>
          ))}
        </Scrollbar>

        {renderTotal}
      </Stack>
    </Card>
  );
}


OrderDetailsItems.propTypes = {
  discount: PropTypes.number,
  items: PropTypes.array,
  shipping: PropTypes.number,
  subTotal: PropTypes.number,
  taxes: PropTypes.number,
  totalAmount: PropTypes.number,
};
