import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/components/loading-screen';

// CUSTOM IMAGE FOR DESKTOP
import UndrawFPassLogo from "src/assets/undraw_forgot_password.svg";
// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
// ----------------------------------------------------------------------


const authJwt = {
  path: 'auth',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Your Gateway to Seamless Shipping">
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <AuthClassicLayout title="Forgot Your Password?" image={UndrawFPassLogo}>
          <JwtForgotPasswordPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  authJwt
];
